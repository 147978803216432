<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <div class="privacy-policy">
                <h1>Privacy Policy</h1>
                <p>Last Updated: 13th December 2024</p>
              
                <section>
                  <h2>1. Introduction</h2>
                  <p>
                    Welcome to the Property Management System ("we," "our," or "us"). We are committed to protecting your privacy and handling your data in a transparent and secure manner. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application and related services.
                  </p>
                </section>
              
                <section>
                  <h2>2. Information We Collect</h2>
              
                  <h3>2.1 Personal Information</h3>
                  <ul>
                    <li>Full name</li>
                    <li>Contact information (phone number, email address)</li>
                    <li>National ID or passport number</li>
                    <li>M-Pesa transaction details</li>
                    <li>Rental unit information</li>
                    <li>Payment history</li>
                    <li>Communication records</li>
                  </ul>
              
                  <h3>2.2 Automatically Collected Information</h3>
                  <ul>
                    <li>Device information (model, operating system)</li>
                    <li>IP address</li>
                    <li>App usage statistics</li>
                    <li>Login timestamps</li>
                    <li>Transaction records</li>
                  </ul>
                </section>
              
                <section>
                  <h2>3. How We Use Your Information</h2>
                  <ul>
                    <li>Process rental payments</li>
                    <li>Generate payment receipts</li>
                    <li>Send rent reminders and notifications</li>
                    <li>Manage maintenance requests</li>
                    <li>Communicate about your rental unit</li>
                    <li>Improve our services</li>
                    <li>Ensure platform security</li>
                    <li>Comply with legal obligations</li>
                  </ul>
                </section>
              
                <section>
                  <h2>4. Data Storage and Security</h2>
                  <p>
                    We implement appropriate technical and organizational measures to protect your personal information, including:
                  </p>
                  <ul>
                    <li>Data encryption in transit and at rest</li>
                    <li>Secure server infrastructure</li>
                    <li>Regular security audits</li>
                    <li>Access control mechanisms</li>
                    <li>Secure payment processing</li>
                  </ul>
                </section>
              
                <section>
                  <h2>5. Data Sharing and Disclosure</h2>
                  <p>We may share your information with:</p>
                  <ul>
                    <li>Property managers and landlords</li>
                    <li>Payment processing partners</li>
                    <li>Cloud service providers</li>
                    <li>Legal authorities when required by law</li>
                  </ul>
                  <p>We do not sell your personal information to third parties.</p>
                </section>
              
                <section>
                  <h2>6. Your Rights</h2>
                  <p>You have the right to:</p>
                  <ul>
                    <li>Access your personal information</li>
                    <li>Request corrections to your data</li>
                    <li>Request deletion of your data</li>
                    <li>Opt-out of marketing communications</li>
                    <li>Export your data</li>
                    <li>File a complaint</li>
                  </ul>
                </section>
              
                <section>
                  <h2>7. Data Retention</h2>
                  <p>
                    We retain your information for as long as:
                  </p>
                  <ul>
                    <li>Your account is active</li>
                    <li>Required by law</li>
                    <li>Necessary for legitimate business purposes</li>
                  </ul>
                </section>
              
                <section>
                  <h2>8. Children's Privacy</h2>
                  <p>
                    Our services are not intended for users under 18. We do not knowingly collect information from children.
                  </p>
                </section>
              
                <section>
                  <h2>9. Changes to Privacy Policy</h2>
                  <p>
                    We may update this Privacy Policy periodically. We will notify you of any material changes through the app or email.
                  </p>
                </section>
              
                <section>
                  <h2>10. Contact Information</h2>
                  <p>For privacy-related inquiries, please contact:</p>
                  <p>[Contact Information]</p>
                </section>
              
                <section>
                  <h2>11. Mobile App Permissions</h2>
                  <p>Our app requires the following permissions:</p>
                  <ul>
                    <li>Camera (for document scanning)</li>
                    <li>Storage (for saving receipts)</li>
                    <li>Internet access</li>
                    <li>Push notifications</li>
                  </ul>
                </section>
              
                <section>
                  <h2>12. Third-Party Services</h2>
                  <p>We use trusted third-party services for:</p>
                  <ul>
                    <li>Payment processing (M-Pesa)</li>
                    <li>Cloud storage</li>
                    <li>Analytics</li>
                    <li>Communication</li>
                  </ul>
                </section>
              
                <section>
                  <h2>13. Data Protection</h2>
                  <p>We comply with applicable data protection laws and regulations, including:</p>
                  <ul>
                    <li>Data Protection Act</li>
                    <li>Consumer protection laws</li>
                    <li>Electronic transaction regulations</li>
                  </ul>
                </section>
              
                <section>
                  <h2>14. User Consent</h2>
                  <p>By using our app, you consent to:</p>
                  <ul>
                    <li>Collection and processing of your data</li>
                    <li>Receiving notifications</li>
                    <li>Storage of your information</li>
                    <li>Sharing with relevant parties</li>
                  </ul>
                </section>
              
                <section>
                  <h2>15. Security Measures</h2>
                  <p>We employ industry-standard security measures:</p>
                  <ul>
                    <li>SSL/TLS encryption</li>
                    <li>Secure data centers</li>
                    <li>Regular backups</li>
                    <li>Intrusion detection</li>
                    <li>Access logging</li>
                  </ul>
                </section>
              
                <section>
                  <h2>16. International Data Transfers</h2>
                  <p>If we transfer your data internationally, we ensure:</p>
                  <ul>
                    <li>Adequate data protection measures</li>
                    <li>Compliance with transfer regulations</li>
                    <li>Appropriate safeguards</li>
                    <li>Transparent processing</li>
                  </ul>
                </section>
              </div>
              
        </div>
    </div>
</div>