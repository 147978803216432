import { Component } from '@angular/core';

@Component({
  selector: 'app-private-policy',
  standalone: true,
  imports: [],
  templateUrl: './private-policy.component.html',
  styleUrl: './private-policy.component.scss'
})
export class PrivatePolicyComponent {

}
